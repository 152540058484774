<template>
  <div class="about">
    <h1>The INDRA Network Search</h1>
    <p>
      The INDRA Network Search makes paths in a causal graph representations of the
      INDRA Database searchable through a user interface and REST API.
    </p>
    <p class="text-center">
      You can find the REST API documentation <a href="https://network.indra.bio/api/docs" target="_blank">here</a>
      (using <a href="https://github.com/swagger-api/swagger-ui" target="_blank">Swagger UI</a>)
      and <a href="https://network.indra.bio/api/redoc" target="_blank">here</a>
      (same information, using <a href="https://github.com/Rebilly/ReDoc" target="_blank">ReDoc</a>).<br>
      You can find this project's documentation, including the codebase,
      <a href="https://indra-network-search.readthedocs.io/en/latest/" target="_blank">here</a>.
      Don't miss the Web UI
      <a href="https://indra-network-search.readthedocs.io/en/latest/ui_introduction.html" target="_blank">introduction page</a>.
    </p>
    <p>
      The code for this project is available on <a href="https://github.com/indralab/indra_network_search" target="_blank">GitHub</a>.
    </p>
    <p>
      To read more about INDRA, see the <a href="https://www.indra.bio/" target="_blank">homepage</a>, the
      <a href="https://indra.readthedocs.io/en/latest/" target="_blank">documentation</a> and the project on
      <a href="https://github.com/sorgerlab/indra" target="_blank">github</a>.
    </p>
    <p>
      This project's backend is powered by <a href="https://fastapi.tiangolo.com/" target="_blank">FastAPI</a>.
      The frontend is made 100 % in <a href="https://v3.vuejs.org/" target="_blank">Vue 3</a>.
    </p>
  </div>
</template>
